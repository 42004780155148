<template>
	<div>
		<PopupLayer
		>
			<template
				v-slot:body
			>
				<v-date-picker
					v-model="date"
					no-title
					scrollable
					style="max-width: 480px"
				></v-date-picker>
			</template>
		</PopupLayer>
	</div>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'DatePicker'
	, components: {PopupLayer}
	, props: ['type', 'date', 'is_move']
	, data: function(){
		return {
			is_view: false
			, year: this.type == 'yy' ? true : false
			, date_picker: {
				date: this.date ? this.date : this.type == 'yy' ? this.$date.init().year : this.$date.getToday('-')
			}
		}
	}
	, computed: {

	}
	, methods: {
		setDate: function(date){
			this.$emit('click', date)
			this.is_view = false
		}
		, prev: function(){
			let date = this.$date.getPrevDay(this.date, '-')
			this.setDate(date)
		}
		, next: function(){
			let date = this.$date.getNextDay(this.date, '-')
			this.setDate(date)
		}
	}
	, created() {
	}
	, watch: {
		date: {
			handler: function(call){
				this.date_picker.date = call ? call : this.type == 'yy' ? this.$date.init().year : this.$date.getToday('-')
			}
		}
	}
}
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active { color: black !important;}
</style>