<template>
	<div class=" ptb-10-pc justify-space-between items-center " :class="{'top-line-pc': !is_top_line}">

		<div
			v-show="option.is_cnt"
			class="ml-10 flex-column justify-center hide-m"
		>
			<span >총 <span class="font-weight-bold color-red size-em-12 ">{{ search.tCnt | makeComma }}</span> 건</span>
		</div>

		<div class="flex-1 flex-column-m justify-end-pc gap-10 text-right">

			<slot
				name="first"
			></slot>

			<div class="justify-space-between-m items-center">
				<span class="flex-1" style="z-index: 1">

					<date_picker
						v-if="false"
						@click="setDate($event, search, 'sDate')"
						class="mr-10-pc"
					></date_picker>
					<input
						v-if="option.sDate"
						v-model="search.sDate"
						class="pa-5-10 input-box-inline width-20-pc width-100-m text-center hide-m"
						placeholder="검색 시작일"

						readonly
						@click="clear(); datePickerStart = !datePickerStart"
					/>

					<div
						v-if="option.sDate"
						class="bg-gray-light text-center pa-10 hide-pc"
						@click="clear(); datePickerStart = !datePickerStart"
					>{{ search.sDate }}</div>

					<PopupLayer
						v-if="datePickerStart"
						max="290"
					>
						<template
							v-slot:body
						>
							<v-date-picker
								v-model="search.sDate"
								no-title
								scrollable
								@change="click"
							></v-date-picker>
						</template>
					</PopupLayer>
				</span>

				<span
					v-if="option.sDate && option.eDate"
					class="pa-10 "
				>~</span>

				<span class="flex-1" >
					<input
						v-if="option.eDate"
						v-model="search.eDate"
						class="pa-5-10 input-box-inline width-20-pc width-100-m mr-10-pc text-center hide-m"
						placeholder="검색 종료일"
						readonly
						@click="clear(); datePickerEnd = !datePickerEnd"
					/>

					<div
						v-if="option.eDate"
						class="bg-gray-light text-center pa-10 hide-pc"
						@click="clear(); datePickerEnd = !datePickerEnd"
					>{{ search.eDate }}</div>

					<PopupLayer
						v-if="datePickerEnd"
						max="290"
					>
						<template
							v-slot:body
						>
							<v-date-picker
								v-model="search.eDate"
								no-title
								scrollable
								@change="click"
							></v-date-picker>
						</template>
					</PopupLayer>
				</span>
			</div>

			<slot
				name="add"
			></slot>

			<template
				v-for="(select, index) in option.select"
			>
				<select
					:key="'select_' + index"
					v-model="search[select.column]"
					class="pa-5-10 box mr-10-pc "
					@change="$emit('click', 1)"
				>
					<option
						:value="''"
					>{{ select.name }}</option>
					<option
						v-for="(item, item_index) in select.items"
						:key="'select_' + index + '_item_' + item_index"
						:value="item.column"
					>{{ item.name }}</option>
				</select>
			</template>


			<select
				v-if="!option.is_list_cnt"
				class="pa-5-10 mr-10-pc hide-m"
				v-model="search.list_cnt"
				@change="$emit('click', 1)"
			>
				<option
					v-for="cnt in list_cnt"
					:key="'cnt_' + cnt"
					:value="cnt"
				>{{ cnt }} 건씩 보기</option>
			</select>

			<div class="justify-end">
				<select
					v-if="!option.is_keyword"
					class="pa-5-10  hide-m"
					v-model="search.search_type"
				>
					<option
						:value="''"
					>검색조건</option>
					<option
						v-for="type in option.search_type"
						:key="'search_type_' + type.column"
						:value="type.column"
					>{{ type.name }}</option>
				</select>

				<input
					v-if="!option.is_keyword"
					v-model="search.search_value"
					class="pa-5-10 box hide-m"
					placeholder="검색어를 입력하세요"
					@keyup.enter="$emit('click', 1)"
				/>

				<input
					v-if="!option.is_keyword"
					v-model="search.search_value"
					class="pa-5-10 box hide-pc width-80"
					placeholder="검색어를 입력하세요"
					@keyup.enter="$emit('click', 1)"
				/>

				<button
					class="btn btn-fill btn-s"
					@click="$emit('click', 1)"
				>검색</button>
			</div>

			<button
				v-if="option.is_excel"
				class="btn-green pa-5-10 mr-10-pc color-white"
				@click="$emit('toExcel')"
			>EXCEL</button>

			<button
				v-if="is_item || option.is_item"
				class="btn btn-fill btn-s"
				@click="$emit('toItem')"
			>등록</button>

			<slot
				name="last"
			></slot>
		</div>
	</div>
</template>

<script>
import date_picker from "@/components/DatePicker"
import PopupLayer from "./PopupLayer";
export default {
	name: 'Search'
	,props: ['search', 'option', 'is_item', 'is_top_line']
	,components: {PopupLayer, date_picker}
	,data: function(){
		return {
			list_cnt: [10, 20, 30, 50, 100, 200]
			,datePickerStart: false
			,datePickerEnd: false
		}
	}
	,methods:{
		clear: function(){
			this.datePickerStart = false
			this.datePickerEnd = false
		}
		, click: function(){
			this.datePickerStart = false
			this.datePickerEnd = false
			this.$emit('click')
		}
		, setDate: function(date) {
			alert(1123123)
			let t = date.split('-')
			this.$emit('click', t)
		}
	}
	, created() {
	}
}
</script>

<style>
.v-picker__body {border: 1px solid #bbb;}
.v-btn--active {background-color: #0f6ecd}
</style>